import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import classes from "./RevenuePopover.module.scss";
import { PR_REVENUE_FILTER } from "../../../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { performanceRevenueFilterSelector } from "../../../../../../../redux/selectors/performanceDataSelector";

export const GENERAL_REVENUE_CATEGORIES: CheckboxRevenueOption[] = [
  {
    label: "Net Accommodation Fare",
    key: "accommodation_fare_adjusted_usd",
    checked: true,
    value: "+",
  },
  {
    label: "Cleaning Fee",
    key: "cleaning_fee",
    checked: false,
    value: "+",
  },
  {
    label: "AH Fee",
    key: "ah_commission_fee",
    checked: false,
    value: "+",
  },
  {
    label: "CC Fee",
    key: "creditcard_fee",
    checked: false,
    value: "+",
  },
  {
    label: "Taxes",
    key: "total_taxes",
    checked: false,
    value: "+",
  },
  {
    label: "Host Channel Fee",
    key: "host_channel_fee_usd",
    checked: true,
    value: "-",
  },
  {
    label: "Total Fees",
    key: "total_fee_usd",
    checked: true,
    value: "+",
  },
];

export const ADMIN_REVENUE_CATEGORIES: CheckboxRevenueOption[] = [
  {
    label: "Guesty Net Income",
    key: "Revenue",
    checked: true,
    forAdmins: true,
    value: "+",
  },
];

export interface CheckboxRevenueOption {
  label: string;
  checked: boolean;
  key: string;
  value: "+" | "-";
  forAdmins?: boolean;
}

interface IRevenuePopoverProps {
  id: string;
  open: boolean;
  anchor: HTMLButtonElement;
  onClose: () => void;
}

export const RevenuePopover = ({
  id,
  open,
  anchor,
  onClose,
}: IRevenuePopoverProps) => {
  const dispatch = useDispatch();
  const filters = useSelector(performanceRevenueFilterSelector);
  const isAdmin = +(sessionStorage.getItem("ROLE_TYPE") || 1) === 1;
  const isSubAdmin = +(sessionStorage.getItem("ROLE_TYPE") || 2) === 2;


  const [selectAll, setSelectAll] = useState<boolean>(true);
  const [checkboxes, setCheckboxes] = useState<CheckboxRevenueOption[]>([
    ...GENERAL_REVENUE_CATEGORIES,
  ]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;
    setSelectAll(newState);
    setCheckboxes(
      checkboxes.map((checkbox) => ({
        ...checkbox,
        checked:
          checkbox.key === "accommodation_fare_adjusted_usd"
            ? (isAdmin || isSubAdmin)
              ? newState
              : checkbox.checked
            : newState,
      }))
    );
  };

  const handleCheckboxChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newState = event.target.checked;
      setCheckboxes(
        checkboxes.map((checkbox, i) =>
          i === index ? { ...checkbox, checked: newState } : checkbox
        )
      );
      setSelectAll(checkboxes.every((checkbox) => checkbox.checked));
    };

  const handleSelectChange =
    (index: number) => (event: SelectChangeEvent<"+" | "-">) => {
      if (checkboxes.filter((el) => el.checked).length > 1) {
        const newValue = event.target.value as "+" | "-";
        setCheckboxes(
          checkboxes.map((checkbox, i) =>
            i === index ? { ...checkbox, value: newValue } : checkbox
          )
        );
      }
    };

  const handleApply = useCallback(() => {
    dispatch({
      type: PR_REVENUE_FILTER,
      payload: {
        revenue_filters: checkboxes,
      },
    });
    onClose();
  }, [checkboxes, dispatch, onClose]);

  const handleReset = useCallback(() => {
    dispatch({
      type: PR_REVENUE_FILTER,
      payload: {
        revenue_filters: null,
      },
    });
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    // if (isAdmin || isSubAdmin)
    if (isAdmin) {
      setCheckboxes([
        ...checkboxes.map((el) => ({ ...el, checked: false })),
        ...ADMIN_REVENUE_CATEGORIES,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAdmin, 
    // isSubAdmin
  ]);

  useEffect(() => {
    if (filters) {
      setCheckboxes(filters);
    }
  }, [filters]);

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      id={id}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
          borderRadius: "24px",
        },
      }}
    >
      <Box className={classes.popoverContentWrapper}>
        <h3
          style={{
            marginBottom: "0px",
            fontSize: "24px",
            color: "#02094F",
            margin: 0,
          }}
        >
          Revenue
        </h3>
        <Box color="#5D6293">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  indeterminate={
                    !selectAll &&
                    checkboxes.some((checkbox) => checkbox.checked)
                  }
                />
              }
              style={{ fontSize: "14px" }}
              label={
                <Typography fontSize="14px" color="#5D6293">
                  Select all
                </Typography>
              }
            />
            {checkboxes.map((checkbox, index) => (
              <Box
                key={index}
                className={classes.checkboxLine}
                display="flex"
                flexDirection="column"
                gap="8px"
              >
                <FormControlLabel
                  control={
                    <Box mr={1}>
                      <Checkbox
                        checked={checkbox.checked}
                        onChange={handleCheckboxChange(index)}
                        disabled={
                          checkbox.key === "accommodation_fare_adjusted_usd" &&
                          !(isAdmin|| isSubAdmin)
                        }
                      />
                      <Select
                        value={checkbox.value}
                        onChange={handleSelectChange(index)}
                        style={{
                          color: "#00B48D",
                          height: "30px",
                          width: "66px",
                          fontWeight: 500,
                        }}
                        sx={{
                          "& .MuiSelect-select": {
                            paddingLeft: "20px",
                          },
                        }}
                        variant="outlined"
                        disabled={
                          checkboxes.filter((el) => el.checked).length < 2 ||
                          (checkbox.key === "accommodation_fare_adjusted_usd" &&
                            !(isAdmin || isSubAdmin))
                        }
                      >
                        <MenuItem value="+">+</MenuItem>
                        <MenuItem value="-">-</MenuItem>
                      </Select>
                    </Box>
                  }
                  label={
                    <Typography fontSize="14px" color="#5D6293">
                      {checkbox.label}
                    </Typography>
                  }
                />
                <Divider />
              </Box>
            ))}
          </FormGroup>
        </Box>

        <Box display="flex" gap="16px">
          <Button
            variant="contained"
            className={`${classes.configureButton} ${classes.resetButton}`}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            className={classes.configureButton}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
