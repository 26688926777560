import { Divider } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PERFOMANCE_HIGHLIGHTS_REQUEST } from "../../../../redux/actions";
import { perfomanceHighlightsSelector } from "../../../../redux/selectors/perfomanceHighlightsSelector";
import { periodList } from "../../../../utils/constants/periodLists";
import { PeriodDropdown } from "../../../common/PeriodDropdown/PeriodDropdown";
import { Highlight } from "../../../common/BasicHighlight/BasicHighlight";
import classes from "./PerfomanceHighlights.module.scss";
import { L12M, N30D, L30D } from "../../../../utils/constants/filterConstants";
import { isMobileModeSelector } from "../../../../redux/selectors/appStatusSelector";
import { performanceDataFilterSelector } from "../../../../redux/selectors/performanceDataSelector";

enum PERIODS_ENUM {
  N30D = "next_30_days",
  L30D = "last_30_days",
  L12M = "last_12_months",
  L3060D = "last_30_to_60_days",
  L1224M = "last_12_to_24_months",
}

export const PerfomanceHighlights = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L30D);
  const data = useSelector(perfomanceHighlightsSelector);

  const filters = useSelector(performanceDataFilterSelector);

  const isMobileView = useSelector(isMobileModeSelector);
  const { uniqueReservations, totalBookedNights, avarageDailyRates, reviews } =
    data || {};

  useEffect(() => {
    dispatch({
      type: PERFOMANCE_HIGHLIGHTS_REQUEST,
      payload: filters
        ? {
            owners: filters.ownersToRequest,
            properties: filters.propertiesToRequest,
            beds: filters.bedroomsToRequest,
            param: period,
          }
        : {
            beds: [],
            owners: [],
            properties: [],
            param: period,
          },
    });
  }, [dispatch, filters, period]);

  const highlightsValues = useMemo(() => {
    if (
      uniqueReservations &&
      totalBookedNights &&
      avarageDailyRates &&
      reviews
    ) {
      switch (period) {
        case L30D:
          return {
            reservationsCurrent: uniqueReservations[PERIODS_ENUM.L30D],
            reservationsPrev: uniqueReservations[PERIODS_ENUM.L3060D],
            nightsCurrent: totalBookedNights[PERIODS_ENUM.L30D],
            nightsPrev: totalBookedNights[PERIODS_ENUM.L3060D],
            avarageDailyRatesCurrent: avarageDailyRates[PERIODS_ENUM.L30D],
            avarageDailyRatesPrev: avarageDailyRates[PERIODS_ENUM.L3060D],
            reviewsCurrent: reviews[PERIODS_ENUM.L30D]?.overall_rating ?? NaN,
            reviewsPrev: reviews[PERIODS_ENUM.L3060D]?.overall_rating ?? NaN,
          };
        case N30D:
          return {
            reservationsCurrent: uniqueReservations[PERIODS_ENUM.N30D],
            // reservationsPrev: uniqueReservations[PERIODS_ENUM.L30D],
            reservationsPrev: '',
            nightsCurrent: totalBookedNights[PERIODS_ENUM.N30D],
            // nightsPrev: totalBookedNights[PERIODS_ENUM.L30D],
            nightsPrev: '',
            avarageDailyRatesCurrent: avarageDailyRates[PERIODS_ENUM.N30D],
            // avarageDailyRatesPrev: avarageDailyRates[PERIODS_ENUM.L30D],
            avarageDailyRatesPrev: '',
            reviewsCurrent: reviews[PERIODS_ENUM.N30D]?.overall_rating ?? NaN,
            // reviewsPrev: reviews[PERIODS_ENUM.L30D]?.overall_rating ?? NaN,
            reviewsPrev: '',

          };
        case L12M:
          return {
            reservationsCurrent: uniqueReservations[PERIODS_ENUM.L12M],
            reservationsPrev: uniqueReservations[PERIODS_ENUM.L1224M],
            nightsCurrent: totalBookedNights[PERIODS_ENUM.L12M],
            nightsPrev: totalBookedNights[PERIODS_ENUM.L1224M],
            avarageDailyRatesCurrent: avarageDailyRates[PERIODS_ENUM.L12M],
            avarageDailyRatesPrev: avarageDailyRates[PERIODS_ENUM.L1224M],
            reviewsCurrent: reviews[PERIODS_ENUM.L12M]?.overall_rating ?? NaN,
            reviewsPrev: reviews[PERIODS_ENUM.L1224M]?.overall_rating ?? NaN,
          };
      }
    }
  }, [
    avarageDailyRates,
    period,
    reviews,
    totalBookedNights,
    uniqueReservations,
  ]);

  return (
    <div className={classes.perfomanceHighlightsWrapper} id="highlights">
      <>
        <div className={classes.highlightsHeader}>
          <h4 style={{ fontSize: isMobileView ? "18px" : "24px" }}>Summary</h4>
          <PeriodDropdown
            className={classes.outlinedDropdown}
            period={period}
            periodList={periodList}
            setPeriod={setPeriod}
          />
        </div>
        {!isMobileView && <Divider flexItem orientation="horizontal" />}
        <div className={classes.highlights}>
          {highlightsValues && (
            <>
              <Highlight
                className={classes.highlight}
                label="Reservations"
                tooltip={"Represents the amount of reservations Checking-in during the selected period"}
                value={highlightsValues?.reservationsCurrent?.toLocaleString(
                  "en-EN"
                )}
                previousValue={highlightsValues?.reservationsPrev}
                showDynamicIndicator
              />
              <Divider
                flexItem
                orientation={isMobileView ? "horizontal" : "vertical"}
              />
            </>
          )}
          {highlightsValues && (
            <>
              <Highlight
                className={classes.highlight}
                label="Occupied Nights"
                tooltip={
                  // "This is the average number of nights that your property was occupied for a given reservation"
                  "Represents the number of nights that your property was occupied for the given period"
                }
                value={highlightsValues.nightsCurrent?.toLocaleString("en-EN")}
                previousValue={highlightsValues.nightsPrev}
              />
              <Divider
                flexItem
                orientation={isMobileView ? "horizontal" : "vertical"}
              />
            </>
          )}
          {highlightsValues && (
            <>
              <Highlight
                className={classes.highlight}
                label="Average Daily Rate"
                prependSymbol={"$"}
                tooltip={
                  "Represents the average daily rate per night for the given period after discounts (e.g. weekly & monthly discounts)."
                }
                value={+highlightsValues.avarageDailyRatesCurrent}
                previousValue={+highlightsValues.avarageDailyRatesPrev}
                showDynamicIndicator
              />
              <Divider
                flexItem
                orientation={isMobileView ? "horizontal" : "vertical"}
              />
            </>
          )}
          {highlightsValues && (
            <>
              <Highlight
                label="Overall Rating"
                rating
                tooltip={
                  "This is the average score given to your property compared to every other property listed on Online Travel Agencies, Airbnb and Booking.com. This number takes into account your property’s cleanliness, value, location, listing accuracy, staff, facilities, communication, check-in simplicty and comfort"
                }
                value={+highlightsValues.reviewsCurrent}
                previousValue={+highlightsValues.reviewsPrev}
                showDynamicIndicator
                isDecimal
              />
            </>
          )}
        </div>
      </>
    </div>
  );
};
